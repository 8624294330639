@import "overrides";
@import "bootstrap/bootstrap";
@import "highlight";

body {
    overflow-x: hidden;
}

.page-header {
    .home-button {
        display: inline-block;

        text-decoration: none;
        color: $gray-500;

        text-transform: uppercase;
        font-size: small;

        div {
            position: relative;
            margin: 1rem;
            padding: 0 15px;

            .back-arrow {
                position: absolute;
                left: 0;
                top: calc(50% - 4px);

                width: 0;
                height: 0;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-right:5px solid $gray-400;
            }
        }

        &:hover {
            color: $gray-600;

            .back-arrow {
                border-right-color: $gray-500;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .page-header {
        .home-button {
            padding: 1rem;
        }
    }
}

.post-title {
    font-weight: 550;

    a {
        color: black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.post-meta {
    margin-left: 2px;
    color: $gray-500;
    margin-bottom: 0.8rem;
    font-size: 0.8rem;

    .author-thumbnail {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        margin-right: 10px;
    }

    .separator {
        margin: 0 5px;
    }
}

article.post {
    .post-title {
        font-weight: bold;
    }

    .post-meta {
        margin-bottom: 1rem;
    }
}

a.vglnk {
    color: inherit;
    text-decoration: none;
    pointer-events: none;
    cursor: default;
}

pre {
    border-radius: 6px;
    padding: 1rem;
}

:not(pre) > code {
    padding: 1px 4px;
    white-space: pre-wrap;
    border: #e3edf3 1px solid;
    background: #f7fafb;
    border-radius: 2px;
    color: black;
}

.post-content {
    position: relative;
    margin-bottom: 4rem;
}

article {
    margin-bottom: 1rem;

    h2, h3, h4 {
        margin-top: 2rem;
    }

    img {
        @include img-fluid;

        margin: 0.8rem 0;
    }

    p {
        margin-top: 1.75rem;
    }

    h2, h3, h4 {
        & + p {
            margin-top: 1rem;
        }
    }
}

.page-item.active .page-link {
    background-color: gray;
    border-color: gray;
}

.post-preview {
    margin: 4rem 0;
}

.author-links {
    position: relative;
    margin-bottom: 10px;
}

.author-links a {
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    float: left;
    margin: 0 20px;
}

.author-links svg {
    height: 32px;
    position: absolute;
    top: 9px;
    left: 9px;
    color: #50585D;
}

.author-links svg:hover {
    color: #000;
}

.author-image {
    display: block;
    background-size: cover;
    background-position: center center;
    border-radius: 100%;
    left: auto;
    width: 120px;
    height: 120px;
    padding: 3px;
    margin: 10px auto 10px auto;
    box-shadow: none;
    background: #fff;
}

.main-header {
    padding: 2rem;
    max-height: 40%;
    background: #f5f8fa;
    text-align: center;

    .author-name {
        font-weight: bold;
        font-size: 1.5rem;
    }

    .author-bio {
        margin-top: 1.5rem;
        font-weight: lighter;
        font-size: 1.2rem;
        line-height: 1.2rem;
    }

    .author-location {
        margin-top: 1.5rem;
        color: $gray-500;
        font-weight: lighter;
        font-size: 1rem;
        font-style: italic;
    }

    .author-links {
        margin-top: 0.5rem;
    }
}

@media only screen and (min-width: 1400px) {
    .table-of-contents {
        position: absolute;
        left: calc(50% + 400px);
        width: 300px;
    }
}

.table-of-contents {
    margin-left: -10px;

    .toc-title {
        font-weight: bold;
        padding-left: 10px;
        padding-bottom: 5px;
    }

    a {
        color: black;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.copyright {
    padding-bottom: 2rem;
    color: $gray-600;
    font-size: 0.8rem;

    .copyright-author, .copyright-powered-by {
        margin: 0.5rem 0;
    }

    .copyright-powered-by {
        a {
            color: $gray-600;
            font-weight: bold;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        .separator {
            margin: 0 5px;
        }
    }
}

@include media-breakpoint-up(sm) {
    .copyright {
        padding-bottom: 3rem;

        .copyright-author {
            float: left;
        }

        .copyright-powered-by {
            float: right;
        }
    }
}

.post-list {
    padding-bottom: 3rem;
}
