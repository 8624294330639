$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 721px,
  xl: 722px,
  xxl: 723px
);

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$h1-font-size:                $font-size-base * 2 !default;
$h2-font-size:                $font-size-base * 1.5 !default;
$h3-font-size:                $font-size-base * 1.25 !default;
$h4-font-size:                $font-size-base * 1 !default;
$h5-font-size:                $font-size-base * 1 !default;
$h6-font-size:                $font-size-base !default;
$headings-font-weight:        600;
